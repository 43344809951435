
































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class StaticPartnerPage extends Vue {
    platinumCompany = [
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/AllwaysLogo_2_whitebackground+(002).jpg",
            title: "Always Health Partners",
            link: "https://www.allwayshealthpartners.org/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/BCBSMA_Logo_NEW+USE!.jpg",
            title: "BCBS-MA",
            link: "https://www.bluecrossma.org/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/UHC_+USE!!!!+11.10.20.png",
            title: "United Health Care",
            link: "https://www.uhc.com/"
        }
    ];
    goldCompany = [
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/Aetna_Logo_200px.png",
            title: "Aetna",
            link: "https://www.aetna.com/index.html"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/Baystate_Logo_Linear_4C_Tag.jpg",
            title: "Baystate Financial",
            link: "https://www.baystatefinancial.com/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/Cigna+logo+5-15.jpg",
            title: "Cigna",
            link: "https://www.cigna.com/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/harvard-pilgrim-2017.jpg",
            title: "Harvard Pilgrim",
            link: "https://www.harvardpilgrim.org/public/home"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/Lockton+logo+for+mc220.jpg",
            title: "Lockton",
            link: "https://global.lockton.com/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/mercer.png",
            title: "Mercer",
            link: "https://www.mercer.com/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/Optum(R)_4C+-+Png.png",
            title: "Optum",
            link: "https://www.optum.com/"
        }
    ];

    silverCompany = [
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/AmgenWithoutWhiteSpaces.jpg",
            title: "Amgen",
            link: "https://www.amgen.com/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/BRI+New+Logo+2020-220x220.png",
            title: "Benefit Resource, Inc",
            link: "https://www.benefitresource.com/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/Gallagher_wTAG_StackedLarge-3D.png",
            title: "Gallagher",
            link: "https://www.ajg.com/us/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/merck.jpg",
            title: "Merck",
            link: "https://www.merck.com/"
        },
        {
            imgPath:
                "https://vmp-media.s3.amazonaws.com/static-images/TUFTS-logo-BLUE.jpeg",
            title: "Tufts Health Plan",
            link: "https://tuftshealthplan.com/"
        }
    ];
}
